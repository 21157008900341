/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on September 30, 2020 */



@font-face {
    font-family: 'akzidenzgroteskregular';
    src: url('akzidenz-grotesk-roman-webfont.woff2') format('woff2'),
         url('akzidenz-grotesk-roman-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'akzidenzgrotesklight';
    src: url('akzidenz-grotesk-light-webfont.woff2') format('woff2'),
         url('akzidenz-grotesk-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'akzidenzgroteskbold';
    src: url('akzidenz-grotesk-bold-webfont.woff2') format('woff2'),
         url('akzidenz-grotesk-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}